export const PROFILE = "/profile";
export const WHAT_ARE_ALIGNERS = "/what-are-aligners";
export const ALIGNERS_MANUFACTURING = "/aligners-manufacturing";
export const BASIC_ALIGNERS = "/basic-aligners";
export const DICTIONARY = "/dictionary";
export const DIGITAL_MODEL_PREPARATION = "/digital-model-preparation";
export const CLASSIFICATION_OF_ORTHODONTIC_ANOMALIES = "/classification-of-orthodontic-anomalies";
export const SOFTWARE_OVERVIEW = "/software-overview";
export const PROTOCOLS_FOR_PLANNING_ORTHODONTIC_TREATMENT_WITH_ALIGNERS_LIGHT = "/protocols-for-planning-orthodontic-treatment-with-aligners-light";
export const PROTOCOLS_ANALYTICAL = "/protocols-analytical";
export const PROTOCOLS_ALIGNERS_AND_ELASTICS = "/protocols-aligners-and-elastics";
export const ATTACHMENTS = "/attachments";
export const PROTOCOLS_CLINICAL = "/protocols-clinical";
export const PROTOCOLS_FOR_PLANNING_ORTHODONTIC_TREATMENT_WITH_ALIGNERS_HARD = "/protocols-for-planning-orthodontic-treatment-with-aligners-hard";
export const CT_ANALYSIS = "/ct-analysis";
export const SMILE_ANALYSIS = "/smile-analysis";
export const COMPANY_POLICY = "/company-policy";
export const PLANNING_AESTHETIC_REHABILITATION = "/planning-aesthetic-rehabilitation";
export const TEETH_LIBRARIES = "/teeth-libraries";
export const SMILE_DESIGN = "/smile-design";
export const MOCAP_DESIGN = "/mocap-design";

export const navs = [
  {
    id: 1,
    route: WHAT_ARE_ALIGNERS,
    name: "Что такое элайнеры",
  },
  {
    id: 2,
    route: ALIGNERS_MANUFACTURING,
    name: "Изготовление элайнеров",
  },
  {
    id: 3,
    route: BASIC_ALIGNERS,
    name: "Базовые знания для производства элайнеров",
  },
  {
    id: 4,
    route: DICTIONARY,
    name: "Словарь ортодонтических терминов",
  },
  {
    id: 5,
    route: DIGITAL_MODEL_PREPARATION,
    name: "Подготовка цифровых моделей",
  },
  {
    id: 6,
    route: CLASSIFICATION_OF_ORTHODONTIC_ANOMALIES,
    name: "Классификация ортодонтических аномалий",
  },
  {
    id: 7,
    route: SOFTWARE_OVERVIEW,
    name: "Обзор 3D программ ",
  },
  {
    id: 8,
    route: PROTOCOLS_FOR_PLANNING_ORTHODONTIC_TREATMENT_WITH_ALIGNERS_LIGHT,
    name: "Протоколы планирования ортодонтического лечения элайнерами",
  },
  {
    id: 9,
    route: PROTOCOLS_ANALYTICAL,
    name: "Аналитические протоколы",
  },
  {
    id: 10,
    route: PROTOCOLS_ALIGNERS_AND_ELASTICS,
    name: "Протоколы использования элайнеров вместе с системами ортодонтических эластиков",
  },
  {
    id: 11,
    route: ATTACHMENTS,
    name: "Методика использования активаторов (аттачменьтов)",
  },
  {
    id: 12,
    route: PROTOCOLS_CLINICAL,
    name: "Клинические протоколы",
  },
  {
    id: 13,
    route: PROTOCOLS_FOR_PLANNING_ORTHODONTIC_TREATMENT_WITH_ALIGNERS_HARD,
    name: "Протоколы планирования ортодонтического лечения элайнерами (сдлжные случаи)",
  },
  {
    id: 14,
    route: CT_ANALYSIS,
    name: "Анализ КТ",
  },
  {
    id: 15,
    route: SMILE_ANALYSIS,
    name: "Анализ улыбки пациента",
  },
  {
    id: 16,
    route: COMPANY_POLICY,
    name: "Политика компаний",
  },
  {
    id: 17,
    route: PLANNING_AESTHETIC_REHABILITATION,
    name: "Планирование эстетической реабилитации пациента после ортодонтического лечения элайнерами.",
  },
  {
    id: 18,
    route: TEETH_LIBRARIES,
    name: "Библиотеки зубов для дизайна улыбки и моделирования цифровых мокапов",
  },
  {
    id: 19,
    route: SMILE_DESIGN,
    name: "Цифровой дизайн улыбки пациента",
  },
  {
    id: 20,
    route: MOCAP_DESIGN,
    name: "Цифровой дизайн мокапа",
  },
];