import { combineReducers } from "redux";

import loginReducer from "./login";
import profileReducer from "./profile";
import { createDialogsReducer } from "./dialogs";
import fileUploadReducer from "./fileUpload";

export default combineReducers({
  login: loginReducer,
  profile: profileReducer,
  fileUpload: fileUploadReducer,
  ...createDialogsReducer(),
});
