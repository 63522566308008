import {API} from "../modules/Api.js";
import Logger from "../modules/Logger";
import {setAuth} from "./loginAction.js";

export const START_OBTAIN_PROFILE_DATA = "START_OBTAIN_PROFILE_DATA";
export const OBTAIN_PROFILE_DATA = "OBTAIN_PROFILE_DATA";
export const OBTAIN_PROFILE_ERROR = "OBTAIN_PROFILE_ERROR";

function startObtainProfileData() {
  return {
    type: START_OBTAIN_PROFILE_DATA,
  };
}

export function obtainProfileData(data) {
  return {
    type: OBTAIN_PROFILE_DATA,
    payload: data,
  };
}

function obtainProfileError(error) {
  return {
    type: OBTAIN_PROFILE_ERROR,
    payload: error,
  };
}

// Get profile
export const getProfile = () => dispatch => {
  dispatch(startObtainProfileData());

  return API.get("auth/profile").then(
    response => {
      Logger.info(response.data);
      dispatch(setAuth());
      const profile = {
        id: response.data.id,
        email: response.data.email,
        avatar: response.data.avatar,
        name: response.data.name,
        country: response.data.country,
        level: response.data.level,
      };
      dispatch(obtainProfileData(profile));
    },
    error => {
      Logger.info(error);
      dispatch(setAuth(true));
      dispatch(obtainProfileError(error));
    },
  );

};

// Update profile
export const updateProfile = ({name, country, email}) => dispatch => {
  return API.put("auth/profile", {
    name, country, email,
  }).then(
    response => {
      Logger.info(response);
      dispatch(obtainProfileData(response.data));
    },
    error => {
      Logger.info(error);
      dispatch(obtainProfileError(error));
    },
  );
};


