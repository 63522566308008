import { API } from "../modules/Api.js";
import Logger from "../modules/Logger";

export const SET_AUTH = "SET_AUTH";
export const START_LOGIN = "START_LOGIN";
export const START_FINISH_REGISTER = "START_FINISH_REGISTER";
export const OBTAIN_LOGIN_DATA = "OBTAIN_LOGIN_DATA";
export const OBTAIN_FINISH_DATA = "OBTAIN_FINISH_DATA";
export const OBTAIN_LOGIN_ERROR = "OBTAIN_LOGIN_ERROR";
export const CLEAR_ERRORS = "CLEAR_ERRORS";


export function clearErrors () {
  return {type: CLEAR_ERRORS};
}

function startLogin () {
  return {type: START_LOGIN};
}

function obtainLoginData () {
  return {type: OBTAIN_LOGIN_DATA};
}

function obtainLoginError (error) {
  return {
    type: OBTAIN_LOGIN_ERROR,
    payload: error,
  };
}

export function login (email, password) {
  return dispatch => {
    dispatch(startLogin());

    let loginData = {
      email: email,
      password: password,
    };

    return API.post("auth/login", loginData).then(
      response => {
        Logger.info(response);
        dispatch(obtainLoginData());
        window.location.href = "/profile";
      },
      error => {
        Logger.info(error);
        dispatch(obtainLoginError(error));
      },
    );
  };
}

export function logout () {
  return dispatch => {
    localStorage.clear();
    dispatch(setAuth());
    window.location.href = "/";
  };
}

export function setAuth (force = false) {
  return {
    type: SET_AUTH,
    payload: !force ? !!localStorage.getItem(process.env.REACT_APP_AUTH_HEADER) : false,
  };
}
