import React, { Suspense } from "react";
import propTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";

const Loader = () => (
  <div className="initialLoader">
    <div className="initialLoader-spinner" />
  </div>
);

export const OpenRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <Suspense fallback={<Loader />}>
        <Component {...props} />
      </Suspense>
    )}
  />
);

export const PrivateRoute = ({ component: Component, auth, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      auth === true ? (
        <Suspense fallback={<Loader />}>
          <Component {...props} />
        </Suspense>
      ) : (
        <Redirect to="/" />
      )
    }
  />
);

OpenRoute.propTypes = {
  // component: propTypes.func.isRequired,
};

PrivateRoute.propTypes = {
  auth: propTypes.bool.isRequired,
  // component: propTypes.func.isRequired,
};
