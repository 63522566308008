import {TOGGLE_DIALOG, SET_DIALOG_DATA} from "../actions";

const dialogsList = ["termsOfUse", "termsConditions", "cookies", "contactUs", "changeEmail", "changePassword", "fullScreen"];

const initialState = {
  isShow: false,
  data: null,
};

export function createDialogsReducer() {
  const reducers = {};
  dialogsList.forEach(dialogName => {
    reducers[dialogName] = (state = initialState, action) => {
      if (action.name !== dialogName) {
        return state;
      }

      switch (action.type) {
        case TOGGLE_DIALOG:
          return {...state, isShow: action.isShow, data: action.data};
        case SET_DIALOG_DATA:
          return {...state};
        default:
          return state;
      }
    };
  });
  return reducers;
}
