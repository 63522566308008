import React, { Component, lazy } from "react";
import propTypes from "prop-types";
import { connect } from "react-redux";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { getProfile } from "./actions";
import {
  ALIGNERS_MANUFACTURING,
  BASIC_ALIGNERS,
  WHAT_ARE_ALIGNERS,
} from "./layouts/MainLayout/Header/navs";
import { OpenRoute, PrivateRoute } from "./Routes";
import "./App.scss";

const LoginLayout = lazy(() => import(/* webpackChunkName: "LoginLayout" */ "./layouts/LoginLayout"));
const AuthLayout = lazy(() => import(/* webpackChunkName: "AuthLayout" */ "./layouts/AuthLayout"));
const MainLayout = lazy(() => import(/* webpackChunkName: "MainLayout" */ "./layouts/MainLayout"));
const Signup = lazy(() => import(/* webpackChunkName: "Signup" */ "./layouts/AuthLayout/Signup"));

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#2196f3",
    },
  },
  typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiButton: {
      root: {borderRadius: 18},
    },
  },
});

class App extends Component {

  render () {
    return (
      <MuiThemeProvider theme={theme}>
        <Router>
          <Switch>
            <OpenRoute exact

              path="/"
              component={LoginLayout} />
            <OpenRoute path={["/login", "/register"]}
              component={AuthLayout} />
            <OpenRoute path={[WHAT_ARE_ALIGNERS, ALIGNERS_MANUFACTURING, BASIC_ALIGNERS]}
              component={MainLayout} />

            <OpenRoute exact
              path="/sign_up/:token"
              component={Signup} />

            <PrivateRoute path="/"
              component={MainLayout}
              auth={this.props.isAuth} />
            <Redirect from="*"
              to="/profile" />
          </Switch>
        </Router>
      </MuiThemeProvider>
    );
  }
}

App.propTypes = {
  isAuth: propTypes.bool.isRequired,
};

const mapStateToProps = ({login}) => ({
  isAuth: login.isAuth,
});

const mapDispatchToProps = {getProfile};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);

