import S3Upload from "../modules/S3Upload";

export const TOGGLE_FILE_UPLOAD = "TOGGLE_FILE_UPLOAD";
export const ADD_FILE_PROGRESS = "ADD_FILE_PROGRESS";
export const SET_FILE_PROGRESS = "SET_FILE_PROGRESS";
export const REMOVE_FILE_PROGRESS = "REMOVE_FILE_PROGRESS";

export function toggleFileUpload(isOpen) {
  return {
    type: TOGGLE_FILE_UPLOAD,
    payload: isOpen,
  };
}

export const uploadToS3 = (file, {type, projectId, incognito_mode}, successCallback) => dispatch => {
  dispatch(addFileProgress(file.name));
  console.log("uploadToS3", type, projectId, incognito_mode);
  return S3Upload(
    file,
    {type, projectId, incognito_mode},
    progress => dispatch(setFileProgress(file.name, progress)),
    response => {
      if (successCallback) {
        successCallback(response);
      }
      dispatch(removeFileProgress(file.name));
    },
  );
};

export function addFileProgress(fileName) {
  return {
    type: ADD_FILE_PROGRESS,
    payload: {fileName, progress: 0},
  };
}

export function setFileProgress(fileName, progress) {
  return {
    type: SET_FILE_PROGRESS,
    payload: {fileName, progress},
  };
}

export function removeFileProgress(fileName) {
  return {
    type: REMOVE_FILE_PROGRESS,
    payload: fileName,
  };
}
